<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <div class="withdrawal-content a-flex-ccc">
            <img src="../../assets/icon/success-icon.png" style="width:69px;height:69px" alt="">
            <span class="a-c-master a-fw-700 fs22" style="padding: 26px 0 17px">提现申请已提交</span>
            <span class="a-c-normal a-fs-14" style="padding: 0 0 35px">请等待管理员审核通过</span>
            <div class="a-flex-rfsc">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="toBusinessCenter">返回商户中心</el-button>
                <el-button class="a-ml-24 a-mt-15 " style="margin-left:24px;width: 104px;" @click="toWithdrawalDetails">
                    查看进度</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            withdrawId: '',//提现申请成功后的id
        }
    },
    mounted() {
        this.withdrawId = this.$route.query.withdrawId
    },
    methods: {
        toBusinessCenter() {
            this.$route.meta.hash = "del";
            this.$router.replace({
                path: '/businessCenter/business-center'
            })
        },
        toWithdrawalDetails() {
            this.$route.meta.hash = "del";
            this.$router.replace({
                path: '/withdrawal/withdrawal-detail',
                query: {
                    code: this.withdrawId
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.withdrawal-content {
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    font-size: 14px;
}

.fs22 {
    font-size: 22px
}
</style>